import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { Section } from 'components/organisms';
import { Hero, Team } from './components';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { Image } from 'components/atoms';
import { Main } from 'layouts';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  sidebarNewsletter: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  footerNewsletterSection: {
    background: theme.palette.primary.dark,
  },
  avatar: {
    width: '60%',
    border: `4px solid ${theme.palette.alternate.dark}`,
    borderRadius: 15,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.1)',
  },
  name: {
    fontWeight: '700',
  },
}));

const BlogArticle = ({ data }) => {
  const classes = useStyles();

  const { profile, allProfiles } = data;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Typography gutterBottom>{text}</Typography>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node) => (
        <Typography paragraph variant="h6">
          {node.content[0].value}
        </Typography>
      ),
      [BLOCKS.HEADING_1]: (node) => (
        <Typography variant="h4" paragraph>
          {node.content[0].value}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node) => (
        <Typography variant="h5" paragraph>
          {node.content[0].value}
        </Typography>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <Image src={node.data.target.fields.file['en-US'].url} alt="imagesf" />
      ),
    },
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <div className={classes.root}>
      <Main>
        <Box mt={8}>
          <Hero />
          <Section id="profile">
            <Grid container spacing={4}>
              <Grid item container xs={12} md={8} spacing={4}>
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="h3"
                    className={classes.name}
                  >
                    {profile.name}
                  </Typography>
                  <Typography variant="h5">{profile.title}</Typography>
                </Grid>
                <Grid item>
                  <Image
                    src={profile.avatar.fluid.src}
                    className={classes.avatar}
                  />
                </Grid>

                <Grid item>
                  {documentToReactComponents(profile.body.json, options)}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Team data={allProfiles} current={profile.order} />
              </Grid>
            </Grid>
          </Section>
        </Box>
      </Main>
    </div>
  );
};

export const query = graphql`
  query GetProfile($order: Int) {
    profile: contentfulProfile(order: { eq: $order }) {
      order
      name
      title
      avatar {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }

    allProfiles: allContentfulProfile(sort: { fields: order, order: ASC }) {
      nodes {
        order
        name
        title
        avatar {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

export default BlogArticle;
